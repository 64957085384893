import { FaBars } from "react-icons/fa";
import "../styles/main.css";
import navlogo from "../Images/logo70.png"
function Navbar() { 
  return (
    <nav>
      <input type="checkbox" id="check" />
      <label htmlFor="check" className="checkbtn">
        <FaBars className="fas fa-bars" />
      </label>
      <img
        className="navlogo"
        src={navlogo}
        height={40}
        width={40}
        style={{ margin: "10px", position: "absolute" }}
        alt="videsta"
      />
      <label className="logo">videsta.lk</label>
      <ul>
        <li>
          <a id="home" className="" 
            style={{ textDecoration: "none" }} 
            href="/">
            Home
          </a>
        </li>
        <li>
          <a
            id="about"
            className=""
            style={{ textDecoration: "none" }}
            href="/About"
          >
            About
          </a>
        </li>
        <li>
          <a
            id="services"
            className=""
            style={{ textDecoration: "none" }}
            href="/Services"
          >
            Services
          </a>
        </li>
        <li>
          <a
            id="contact"
            className=""
            style={{ textDecoration: "none" }}
            href="/Contact"
          >
            Contact
          </a>
        </li>
      </ul>
      
        
      
    </nav>
  );
}

export default Navbar;
