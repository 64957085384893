import "../styles/footer.css";
import { FaWhatsapp } from "react-icons/fa";
export default function Footer() {
  return (
    <div>
      <footer>
        <p className="footer">© 2024 Videsta.lk . All Rights Reserved.</p>
        <a href="tel:+94742004950">
          <FaWhatsapp
            className="fas fa-whatsapp "
            style={{
              fontSize: "28px",
              position: "absolute",
              bottom: "5px",
              right: "5px",
              zIndex: "10",
              color: "green",
              backgroundColor: "white",
            }}
          />
        </a>
      </footer>
    </div>
  );
}
