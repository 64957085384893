import { useEffect } from "react";
import $ from "jquery";
import "../../src/styles/about.css";
import { Button } from "react-bootstrap";
export default function About() {
  useEffect(() => {
    $("#about").addClass("active");
  });
  return (
    <div className="about_main">
      <div className="about_out">
        <div className="about_in">
          <div className="about_content">
            <h3 className="topic">Who Are We ? </h3>
            <div className="contents">
              &nbsp;&nbsp;&nbsp;Videsta (Pvt) Ltd is one of the leading
              prominent and professional Human Resource consultancies in world
              wide.We have gained vast experience in providing the right person
              for the given job.
              <br />
              <br />
              <Button className="--btn --btn-primary">Learn More +</Button>
            </div>
          </div>
          <div className="about_content">
            <h3 className="topic">Our Network ? </h3>
            <div className="contents">
              &nbsp;&nbsp;&nbsp;Our Network spans across countries and
              continents. It helps to fined your dream job easy. Specially in
              Quatar and Romania.
              <br />
              <br />
              <Button className="--btn --btn-primary">Learn More +</Button>
            </div>
          </div>
          <div className="about_content">
            <h3 className="topic">Our Staff ? </h3>
            <div className="contents">
              &nbsp;&nbsp;&nbsp;Our competitive staff always stay behind you
              while giving support. They will guid you from processing passport
              to grant visa.
              <br />
              <br />
              <Button className="--btn --btn-primary">Learn More +</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
