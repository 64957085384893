import home6 from "../src/Images/home6.jpg";
import home7 from "../src/Images/home7.jpeg";
import home8 from "../src/Images/home8.jpeg";
import home9 from "../src/Images/home9.jpeg";
export const sliderData = [
  {
    image: home6,
    heading: "We Provide Quality MANPOWER",
    desc: "Videsta Foreign Employment Agency’s business is about the people. In every undertaking that we do, ultimate client satisfaction and welfare of our recruits will always be at the forefront.",
  },
  {
    image: home7,
    heading: "Job Vacancies in Quatar & Romania",
    desc: "House Maid, Security, Nany, Welder, Plumber, Delivery Rider and More+ jobs In Quatar and Romania",
  },
  {
    image: home8,
    heading: "Bench Mark",
    desc: "Nation’s leading Domestic and Overseas Recruitment Agency which has set a benchmark among its business clients and competitors.",
  },
  {
    image: home9,
    heading: "RECRUITMENT PROCESS",
    desc: "Our dedicated team helps on end to end process of recruitment by enhancing the competitiveness of the organizations and people we serve.",
  },
];
