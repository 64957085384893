import "../styles/notfound.css";
export default function Notfound() {
  return (
    <div className="nfout">
      <div className="nfin">
        <h1>404</h1>
        <h3>Not Found</h3>
      </div>
    </div>
  );
}
