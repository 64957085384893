import { useEffect } from "react";
import $ from 'jquery';
import { Button } from "react-bootstrap";
import "../../src/styles/contact.css";
import { FaPhone, FaWhatsapp, FaLocationArrow, FaMailBulk} from "react-icons/fa";
export default function Contact() {
  useEffect(() => {
     $("#contact").addClass("active");
   });
  return (
    <div className="contact_main">
      <div className="contact_out">
        <div className="contact_in">
          <div className="description">
            <h3 className="topic1">Contact Us</h3>
            <FaPhone className="fas fa-phone fa-phones"></FaPhone>
            <span className="topic2">Telephone</span>
            <p className="details">
              +94 66 228 4949 &nbsp;&nbsp;&nbsp;
              <a href="tel:+94742004950">
                <FaPhone className="fas fa-phone fa-phones1"></FaPhone>
              </a>
            </p>
            <p className="details">
              +94 74 200 4949 &nbsp;&nbsp;&nbsp;
              <a href="tel:+94742004949">
                <FaPhone className="fas fa-phone fa-phones1"></FaPhone>
              </a>
            </p>
            <p className="details">
              +94 74 200 4950 &nbsp;&nbsp;&nbsp;
              <a href="tel:+94742004950">
                <FaPhone className="fas fa-phone fa-phones1"></FaPhone>
              </a>
            </p>
            <FaWhatsapp className="fas fa-phone fa-whatsapp fa-phones"></FaWhatsapp>
            <span className="topic2">Whatsapp</span>
            <p className="details">
              +94 71 605 9049 &nbsp;&nbsp;&nbsp;
              <a href="https://wa.me/+94716059049">
                <FaWhatsapp className="fas fa-whatsapp fa-phones1"></FaWhatsapp>
              </a>
            </p>
            <FaLocationArrow className="fas fa-phone fa-whatsapp fa-phones"></FaLocationArrow>
            <span className="topic2">Address</span>
            <p className="details">
              601/5,
              <br />
              Anuradhapura Road,
              <br />
              Dambulla,
              <br />
              Sri Lanka
            </p>
            <FaMailBulk className="fas fa-phone fa-whatsapp fa-phones"></FaMailBulk>
            <span className="topic2">Email</span>
            <p className="details">
              videstaforeignemployment@gmail.com &nbsp;&nbsp;&nbsp;
              <a href="mailto:videstaforeignemployment@gmail.com">
                <FaMailBulk className="fas fa-email fa-phones1"></FaMailBulk>
              </a>
            </p>
          </div>
          <div className="description description1 ">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2794.626812483426!2d80.64810425162246!3d7.874220381185472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afca540e44aaa1b%3A0x4e289945adc785df!2zRGFtYnVsbGEgQ2xvY2sgVG93ZXIg4Lav4La54LeU4La94LeK4La9IOC2lOC2u-C2veC3neC3g-C3lCDgtprgtqvgt5Tgt4A!5e0!3m2!1sen!2slk!4v1713812285171!5m2!1sen!2slk" width="400" height="300" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

