
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Navbar from "./components/Navbar";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Notfound from "./components/Notfound";
import Footer from "./components/Footer";


function App() { 
  return (
      <div className="App">
        <BrowserRouter>
          < Navbar style={{ position: "absolute", top: "0" }}></Navbar>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="About" element={<About />} />
            <Route path="Contact" element={<Contact />} />
            <Route path="Services" element={<Services />} />
            <Route path="*" element={<Notfound />} />  
          </Routes>

          <Footer></Footer>
        </BrowserRouter>
      </div>
    
  );
}

export default App;
