import { useEffect } from "react";
import $ from "jquery";
import { Button } from "react-bootstrap";
import "../../src/styles/services.css";
export default function Services() {
  useEffect(() => {
    $("#services").addClass("active");
  });
  return (
    <div className="services_main">
      <div className="services_out">
        <div className="services_in">
          <div className="services_content">
            <h3 className="topic">DOCUMENTATION</h3>
            <div className="contents">
              &nbsp;&nbsp;&nbsp;We take the whole responsibility of processing
              the relevant documents that required in sending an employee to the
              foreign service in a legal manner.
              <br />
              <br />
              <Button className="--btn --btn-primary">Learn More +</Button>
            </div>
          </div>
          <div className="services_content">
            <h3 className="topic">VISA/PASSPORT</h3>
            <div className="contents">
              &nbsp;&nbsp;&nbsp;We do visa and passport services only for our
              customers to ensure that they receive everything under a proper
              legal framework.
              <br />
              <br />
              <Button className="--btn --btn-primary">Learn More +</Button>
            </div>
          </div>
          <div className="services_content">
            <h3 className="topic">EMERGENCIES</h3>
            <div className="contents">
              &nbsp;&nbsp;&nbsp;We take full responsibility and do necessary
              steps in the deportation process since the deportation happened
              due to personal, health and legal reasons of employees.
              <br />
              <br />
              <Button className="--btn --btn-primary">Learn More +</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
